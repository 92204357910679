.ck.ck-content ul,
.ck.ck-content ul li {
	list-style-type: inherit;
}

.ck.ck-content ul {
	padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
	list-style-type: decimal;
}

.ck.ck-content ol {
	padding-left: 40px;
}

body {
    --ck-z-default: 1000;
    --ck-z-modal: calc( var(--ck-z-default) + 999 );
}

/* Used to display parsed rich text */

ul,
ol {
	list-style-position: inside;
}
